import React from "react";
import { Link } from "gatsby";

import "./nav.css"; 
import logo from "../images/logo.png";

const bottom_style = "2px solid #000000";

const Nav = () => (
  <nav className="nav">
    <div className="nav-list">
      <div className="nav-list-item">
        <Link
          activeStyle={{ borderBottom: bottom_style }}
	        to="/">
          Home
        </Link>
      </div>
      <div className="nav-list-item">
        <Link  
          activeStyle={{ borderBottom: bottom_style }}
          to="/about">
          About
       </Link>
      </div>
      <div className="nav-list-item">
        <Link  
          activeStyle={{ borderBottom: bottom_style }}
          to="/contact">
          Contact
        </Link>
      </div>
    </div>
    <div className="logo">
      <img src={logo} alt="Logo"
        width="90vw" height="70vh"  
      />
    </div>
  </nav>
);

export default Nav;

