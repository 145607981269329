import React from "react";
import Layout from "../components/Layout";

import headshot from "../images/headshot.png";

const About = () => (
<Layout>
    <h1>About Me</h1>
    <p>
      Hello, internet people! My name is Patrick, and this is my website.
      I'm a Computing Engineering major at the University of Notre Dame, 
      and I made this site to share all of my favorite parts about science,
      math, and programming with all of you. I'm especially interested in 
      A.I. and machine learning, and can't wait to help others learn and
      get excited about the subject.
    </p>
    <div className="centered">
    <img src={headshot} alt="Headshot" 
        width="450vw" height="600vh"
    />
    </div>
    <h3>Early Background</h3>
    <p>
      I grew up in the suburbs of Chicago, and I feel like I've always been
      a nerd of some kind or another. When I was a kid, I was always coming
      up with elaborate games of some kind or another, with rules way more
      complex than they needed to be. I feel like this kind of exploration
      of fantasy worlds of my own design was my first step into the world of
      programming. Thinking about the societal ramifications of bending in 
      Avatar: The Last Airbender or what goes on inside of a Pokeball almost
      feels like working on a proof or writing some new program.
    </p>
    <p>
      My first experience  with programming came, like I think it did for a 
      lot of kids, from math class. I was amazed that I could write a 
      few lines of TI-Basic to solve the Pythagorean theorem, or even make
      a ball bounce back and forth. It almost felt like discovering a super
      power, and has helped contribute to why I still love math to this day.
    </p>
    <p>
      While math introduced me to programming for the first time, it was 
      science which that introduced my to Python, and programming for the
      computer in general. I was on Science Olympiad, and my friends were
      competing in an event where they had to launch something out of a 
      home-made cannon and hit a target a set distance away. I wanted to 
      help, so I wrote a Python program which collected data from previous
      launches and provided suggestions for future launches. I've been 
      programming ever since then, finding new ways to use it to make my
      life easier or solve some problem.
    </p>
    <p>
      After bouncing around a lot, I've landed on Computer Engineering as
      my major of choice. It lets me combine my passions for math, physics,
      and programming into one cohesive whole. The skillset I've been building
      has let me build a chess AI, write code to fly up in a rocket, and even
      utilize the power of machine learning to do everything from optimizing 
      embedded systems to composing love poetry for my girlfriend. 
    </p>
    <h3>Professional Development</h3>
    <p>
      I've been fortunate to have the opportunity to apply my programming skills
      to everywhere from the insurance industry to machine learning research in 
      embedded systems and health care. Along the way I've learned valuable skills,
      like how to write well-documented code which can be read and understood by
      others, automate tasks to save hours of employee labor, and gain an
      understanding of complex concepts through research and experimentation. 
      My career is just beginning, and I can't wait to continue learning about 
      everything the world of software has to offer!
    </p>
</Layout>
);

export default About;
