import React from "react";
import { Link } from "gatsby";

import "./footer.css"

const Footer = () => (
  <footer className="footer">
    <div className="footer-links">
      <Link className="footer-item" to="/">Home</Link>
      <Link className="footer-item" to="/about">About</Link>
      <Link className="footer-item" to="/contact">Contact</Link>
    </div>
    <div className="footer-copyright">
        <p>© Patrick Faley</p>
    </div>
  </footer>
);

export default Footer;
